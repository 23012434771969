import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { getClassWiseSubjects } from '../../services/SubjectServices';
import { getPrograms } from '../../services/ProgramServices';
import { getTeacherSubjectMapping, createTeacherSubjectMapping, updateTeacherSubjectMapping } from '../../services/TeacherSubjectMappingService';
import TeacherSubjectMappingForm from './TeacherSubjectMappingForm';
import Loader from '../../components/Loader/Loader';

const TeacherMappingManage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [programs, setPrograms] = useState([{ id: '', subjects: [] }]);
  const [subjectData, setSubjectData] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [class_in_charge_ids, setClassInChargeIds] = useState([]);
  const [user, setUser] = useState({});
  const [loader, setLoader] = useState(false);
  const path = window.location.pathname;

  const classInChargeAdd = (e) => {
    setClassInChargeIds([...e]);
  };
  const classInChargeRemove = (e) => {
    setClassInChargeIds([...e]);
  };

  const fetchTeacherSubjects = async () => {
    try {
      setLoader(true);
      const session_id = localStorage.getItem('session_id');
      const response = await getTeacherSubjectMapping(params.id, session_id);
      if (response.status === 200) {
        const teacherData = response.data.data.programs;
        setClassInChargeIds(response.data.data.class_in_charge);
        setUser(response.data.data.user);
        teacherData && teacherData.forEach(async (item, index) => {
          let subject_list = await subjectChange(item.id);
          setPrograms((prevPrograms) => {
            const updatedPrograms = [...prevPrograms];
            updatedPrograms[index] = {
              ...updatedPrograms[index],
              id: item.id,
              subjects: item.subjects.map(val => val),
              subjectData: subject_list
            };
            return updatedPrograms;
          });
        });


      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    async function fetchPrograms() {
      try {
        const response = await getPrograms();
        if (response.success) {
          setProgramData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchPrograms();
    fetchTeacherSubjects();
  }, []);


  const handleChangeSubject = (e, id) => {
    setPrograms(prevPrograms => {
      const updatedPrograms = [...prevPrograms];
      updatedPrograms[id] = { ...updatedPrograms[id], subjects: e.map(val => val) };
      return updatedPrograms;
    });
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...programs];
    list[index][name] = value;
    subjectChange(e.target.value);
  };

  const subjectChange = async (programID) => {
    try {
      const res = await getClassWiseSubjects(programID);
      const subjectData = res.data.data;
      setSubjectData(subjectData);
      return subjectData;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const addNewRow = () => {
    setPrograms(prevPrograms => [...prevPrograms, { id: '', subjects: [] }]);
  };

  const onSubjectRemove = (event, programId) => {
    setPrograms(prevPrograms => {
      return prevPrograms.map(item => {
        if (item.id === programId) {
          return { ...item, subjects: event };
        }
        return item;
      });
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const session_id = localStorage.getItem('session_id');
    const updatedPrograms = programs && programs.map(item => ({
      id: item.id,
      subjects: item.subjects.map(subItem => subItem.id),
    }));
    const clsInChargeIds = class_in_charge_ids && class_in_charge_ids.map(item => item.id);
    const data = {
      session_id,
      programs: updatedPrograms,
      class_in_charge_ids: clsInChargeIds,
    };

    try {
      const response = (path.includes('teacher/mapping/update')) ? await updateTeacherSubjectMapping(params.id, data) :
        await createTeacherSubjectMapping(params.id, data);
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: "success" });
        navigate('/teacher/list');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const clickOnDelete = (record) => {
    setPrograms(prevPrograms => prevPrograms.filter(r => r !== record));
  };

  const back = () => {
    navigate('/teacher/list');
  };

  return (
    <div className="content">
      {loader ? <Loader /> :
        <TeacherSubjectMappingForm
          user={user}
          programs={programs}
          programData={programData}
          subjectData={subjectData}
          class_in_charge_ids={class_in_charge_ids}
          classInChargeAdd={classInChargeAdd}
          classInChargeRemove={classInChargeRemove}
          handleChange={handleChange}
          onSubjectRemove={onSubjectRemove}
          handleChangeSubject={handleChangeSubject}
          clickOnDelete={clickOnDelete}
          addNewRow={addNewRow}
          handleSubmit={handleSubmit}
          back={back}
        />}
    </div>
  );
};

export default TeacherMappingManage;
