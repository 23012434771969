import axios from 'axios';

const organization_id = localStorage.getItem('organization_id');
const session_id = localStorage.getItem("session_id");

// axios.defaults.baseURL = 'http://localhost:8000/api/v1';
// const baseURL = "http://localhost:8000";

// axios.defaults.baseURL = 'http://localhost:8001/api/';
// const baseURL = "http://localhost:8001";

axios.defaults.baseURL = 'https://evaluation-api.apeejay.edu/api/v1';
const baseURL = "https://evaluation-api.apeejay.edu";

// axios.defaults.baseURL = 'http://43.204.137.210:8090/api/v1';
// const baseURL = "http://43.204.137.210:8090";

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['organization-id'] = organization_id;
axios.defaults.headers.common['session-id'] = session_id;

export default baseURL;
