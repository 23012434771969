import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTeachers } from "../../redux/teacher/teacherActions";
import { fetchSubjects } from "../../redux/subject/subjectActions";
import { fetchPrograms } from '../../redux/program/programActions';
import Loader from "../../components/Loader/Loader";
import { FaChalkboardTeacher } from "react-icons/fa";
import { tableConstants } from "./teacherTableConstant";
import Table from "../../components/Table/Table";
import Pagination from "../../components/Pagination/Pagination";
import BulkUploadTeacherSubjectProgram from "./BulkUploadTeacherSubjectProgram";
import * as XLSX from 'xlsx';
import { enqueueSnackbar } from "notistack";
import ExcelSample from "./ExcelSample.json";
import { updateTeacherPassword, bulkUploadTeachersClassSubject } from "../../services/TeacherSubjectMappingService";
import { debounce } from 'lodash'; // Import debounce from lodash

const TeacherList = ({ history }) => {
  const [teacherData, setTeacherData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [resetPasswordUserId, setResetPasswordUserId] = useState(null);
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const [name, setName] = useState("");
  const [isBulk, setIsBulk] = useState(false);
  const [file, setFile] = useState(null);

  const dispatch = useDispatch();
  const programData = useSelector((state) => state.program.programs.data) ?? [];
  const teacherDataFromStore = useSelector((state) => state.teacher.teachers.data) ?? [];
  const subjectData = useSelector((state) => state.subject.subjects.data) ?? [];
  const currentPage = useSelector((state) => state.teacher.teachers.current_page);
  const lastPage = useSelector((state) => state.teacher.teachers.last_page);
  const loader = useSelector((state) => state.teacher.loading);

  useEffect(() => {
    if (!localStorage.getItem("organization_id")) {
      enqueueSnackbar("Select any organization!", { variant: "error" });
    }
    dispatch(fetchTeachers(name, { selected: 0 }));
    dispatch(fetchPrograms({ selected: 0 }));
    dispatch(fetchSubjects({ selected: 0 }));
  }, []);

  // Debounced search function
  const handleSearch = useCallback(debounce((value) => {
    dispatch(fetchTeachers(value, { selected: 0 }));
  }, 500), []);

  const handleInputChange = (e) => {
    const { value } = e.target;
    if (value.length > 2|| value.length === 0)
      handleSearch(value); // Trigger the debounced search
  };

  const resetPasswordChangeHandler = (e) => {
    setNewPassword(e.target.value);
  };

  const resetPasswordHandler = async (e, id) => {
    e.preventDefault();
    try {
      if (newPassword === "") {
        enqueueSnackbar("Please enter new password", { variant: "error" });
        return;
      }
      const response = await updateTeacherPassword(id, { new_password: newPassword });
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: "success" });
        setResetPasswordUserId(null);
      }
    }
    catch (error) {
      enqueueSnackbar(error.response.data.message.error, { variant: "error" });
    }
  };

  const resetPasswordStatusHandler = (id) => {
    setResetPasswordUserId(id);
  };

  const excelFileReader = (e) => {
    const session_id = localStorage.getItem('session_id');
    const organization_id = localStorage.getItem('organization_id');

    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      const result = {};

      workbook.SheetNames.forEach(sheet => {
        const json_data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        result[sheet] = json_data;
      });

      let final_data = result['Sheet1'];

      let classNameIdMap = [];
      let subjectNameIdMap = [];

      programData.forEach((item) => {
        let key = item.name && item.name.toLowerCase().replace(/\s/g, '');
        classNameIdMap[key] = item.id;
      });
      subjectData.forEach((item) => {
        let key = item.name.toLowerCase().replace(/\s/g, '');
        subjectNameIdMap[key] = item.id;
      });

      let finalData = final_data.map((item) => {
        let className = item.class.toLowerCase().replace(/\s/g, '');
        let teacher_email = item.teacher_email;
        let subjectName = item.subject_name.toLowerCase().replace(/\s/g, '');
        let program_id = classNameIdMap[className];
        let subject_id = subjectNameIdMap[subjectName];
        return {
          teacher_email, subject_id, program_id, organization_id, session_id,
        };
      });

      setFile(finalData);
    };
    reader.readAsBinaryString(e.target.files[0]);
  };

  const bulkUpload = async () => {
    if (file === null || file === undefined) {
      enqueueSnackbar("Please select a file", { variant: "error" });
      return;
    } else {
      let data = { file };
      try {
        setLoading(true);
        const response = await bulkUploadTeachersClassSubject(data);
        if (response.status === 200) {
          setLoading(false);
          enqueueSnackbar(response.data.message.success, { variant: "success" });
          history.push('/teacher/list');
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const downloadExcel = (e) => {
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(ExcelSample);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `teacher-subject-mapping-sample.xlsx`);
  };

  const downloadTeacherExcel = (e) => {
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(teacherDataFromStore);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `teacher-list.xlsx`);
  };

  return (
    <React.Fragment>
      {loader ? (
        <Loader />
      ) : (
        <div className="card">
          <div className="card-header">
            <div className="">
              <div className="main-title-page page-title d-flex justify-content-space-between">
                <div className="col-md-3">
                  <FaChalkboardTeacher />
                  <span className="mx-2">Subject Teacher Mapping</span>
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by name..."
                    onChange={handleInputChange} // Use debounced input handler
                  />
                </div>
                <div className="col-md-3 mx-2">
                  {permissions.includes("bulk.upload.teacher.class.subject") && (
                    <button
                      className="btn btn-primary"
                      onClick={() => setIsBulk(!isBulk)}
                    >
                      {!isBulk ? "Bulk Upload" : "User List"}
                    </button>
                  )}
                  {!isBulk &&
                    <button
                      className="btn btn-primary mx-1"
                      onClick={downloadTeacherExcel}
                    >
                      Download Excel
                    </button>
                  }
                </div>

              </div>
            </div>
          </div>
          {isBulk ? (
            <BulkUploadTeacherSubjectProgram
              downloadExcel={downloadExcel}
              excelFileReader={excelFileReader}
              bulkUpload={bulkUpload}
            />
          ) : (
            <div className="card-body evaluating-table">
              {teacherDataFromStore && teacherDataFromStore.length ? (
                <div>
                  <Table
                    cols={tableConstants(
                      resetPasswordHandler,
                      setTeacherData,
                      resetPasswordStatusHandler,
                      resetPasswordChangeHandler,
                      resetPasswordUserId,
                      permissions,
                      currentPage
                    )}
                    data={teacherDataFromStore}
                    hoverable={true}
                    striped={true}
                  />
                  <Pagination
                    totalPage={lastPage}
                    handlePageChange={(i) => dispatch(fetchTeachers(name, i))}
                  ></Pagination>
                </div>
              ) : null}
            </div>
          )}
        </div>
      )
      }
    </React.Fragment>
  );
};

export default TeacherList;
