import React from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';

const AssessmentInfo = ({ eventInfo, back }) => {
  return (
    <div className="animated">
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center">
            <div className="assessment-title">
              <strong>Assessment Details</strong>
            </div>
            <div className="assessment-btn">
              <Button variant="secondary" onClick={back} type="submit">
                Back
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-4 pb-1" key={1}>
              <label>
                <strong>Name</strong>
              </label>
              <br />
              {eventInfo.name.name}
            </div>
            <div className="col-md-4 pb-1 border-left border-right" key={8}>
              <label>
                <strong>Subject</strong>
              </label>
              <br />
              {eventInfo.subject.name}
            </div>
            <div className="col-md-4 pb-1" key={9}>
              <label>
                <strong>Evaluation</strong>
              </label>
              <br />
              {eventInfo.evaluation_type.name}
            </div>
            <div className="col-md-4 pb-1" key={7}>
              <label>
                <strong>Class</strong>
              </label>
              <br />
              {eventInfo.program.name}
            </div>

            <div className="col-md-4 pb-1 border-left border-right" key={10}>
              <label>
                <strong>Grade Scheme</strong>
              </label>
              <br />
              {eventInfo.grade_scheme.name}
            </div>
            <div className="col-md-4 pb-1" key={4}>
              <label>
                <strong>Max Score</strong>
              </label>
              <br />
              {eventInfo.max_score ? eventInfo.max_score : "NA"}
            </div>
            <div className="col-md-4 pb-1">
              <label>
                <strong>Created</strong>
              </label>
              <br />
              {moment(eventInfo.created_at).format("DD-MMM-YYYY")}
            </div>
            <div className="col-md-4 pb-1 border-left border-right" key={2}>
              <label>
                <strong>Start</strong>
              </label>
              <br />
              {moment(eventInfo.start).format("DD-MMM-YYYY")}
            </div>
            <div className="col-md-4 pb-1" key={3}>
              <label>
                <strong>End</strong>
              </label>
              <br />

              {moment(eventInfo.end).format("DD-MMM-YYYY")}
            </div>
            <div className="col-md-12" key={6}>
              <label>
                <strong>Description</strong>
              </label>
              <br />
              {eventInfo.description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentInfo;
