import { ENDPOINTS } from "../endpoints";
import axios from "axios";

export const getLeaves = async (program_id, session_id, leave_date, status, page) => {
  try {
    const response = await axios.get(ENDPOINTS.LEAVE.GET, {
      params: {
        data: {
          program_id: program_id,
          session_id: session_id,
          leave_date: leave_date,
          status: status
        },
        page: page
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const getLeaveById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.LEAVE.GET_LEAVE}/${id}`);
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const createLeave = async (formData) => {
  try {
    const response = await axios.post(ENDPOINTS.LEAVE.CREATE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const updateLeave = async (id, formData) => {
  try {
    const response = await axios.post(`${ENDPOINTS.LEAVE.UPDATE}/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    );
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const deleteLeave = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.LEAVE.DELETE}/${id}`);
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const leaveApproval = async (data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.LEAVE.LEAVE_APPROVAL}`, { data });
    console.log(response,"Leave Approval");
    return response;
  }
  catch (error) {
    throw error;
  }
}
