// src/Routing.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import SessionCreate from "./pages/Session/SessionCreate";
import ProgramList from "./pages/Program/ProgramList";
import ProgramCreate from "./pages/Program/ProgramCreate";
import OrganizationCreate from "./pages/Organization/OrganizationCreate";
import OrganizationList from "./pages/Organization/OrganizationList";
import ScoreSchemesList from "./pages/ScoreSchemes/ScoreSchemesList";
import ScoreSchemesCreate from "./pages/ScoreSchemes/ScoreSchemesCreate";
import SubjectCreate from "./pages/Subject/SubjectCreate";
import SubjectList from "./pages/Subject/SubjectList";
import EvaluationTypeCreate from "./pages/EvaluationType/EvaluationTypeCreate";
import EvaluationTypeList from "./pages/EvaluationType/EvaluationTypeList";
import TeacherList from "./pages/TeacherSubjectProgramMap/TeacherList";
import TeacherMappingManage from './pages/TeacherSubjectProgramMap/TeacherMappingManage';
import TraitList from "./pages/Trait/TraitList";
import TraitCreate from "./pages/Trait/TraitCreate";
import UserCreate from "./pages/User/UserCreate";
import UserList from "./pages/User/UserList";
import RolePermission from "./pages/RolePermission/RolePermission";
import ReportCardManage from "./pages/ReportCard/ReportCardManage";
import RoleList from "./pages/RolePermission/RoleList";
import TemplateList from "./pages/template/TemplateList";
import TemplateManage from "./pages/template/TemplateManage";
import AssessmentList from "./pages/Assessment/AssessmentList";
import AssessmentManage from "./pages/Assessment/AssessmentManage";
import MarkingApprovalList from "./pages/MarkingApproval/MarkingApprovalList";
import MarkingApprovalManage from "./pages/MarkingApproval/MarkingApprovalManage";
import PublishResultManage from "./pages/PublishResult/PublishResultManage";
import UpdatePassword from "./pages/Password/UpdatePassword";
import AssessmentMarkingList from "./pages/AssessmentMarking/AssessmentMarkingList";
import ExamManage from "./pages/Exam/ExamManage";
import AssessmentParticipantsMarking from "./pages/AssessmentMarking/AssessmentParticipantsMarking";
import ExamList from "./pages/Exam/ExamList";
import PublishResultList from "./pages/PublishResult/PublishResultList";
import StudentList from "./pages/Student/StudentList";
import StudentManage from "./pages/Student/StudentManage";
import PromoteStudent from "./pages/Student/Promote/PromoteStudent";
import TheLayout from './containers/TheLayout';
import Login from "./pages/Login/Login";
import Page404 from "./pages/Page404/Page404";
import SessionList from './pages/Session/SessionList';
import Dashboard from './pages/dashboard/Dashboard';
import ManagePromotedStudents from './pages/Student/Promote/ManagePromotedStudents';
import SyncStudentList from './pages/Student/SyncStudents/SyncStudentList';
import StudentSubjectMappingList from './pages/StudentSubjectMapping/StudentSubjectMappingList';
import StudentSubjectMappingManage from './pages/StudentSubjectMapping/StudentSubjectMappingManage';
import AttendanceList from './pages/AppManagement/Attendence/AttendanceList';
import AttendanceManage from './pages/AppManagement/Attendence/AttendanceManage';
import LeaveList from './pages/AppManagement/Leave/LeaveList';
import LeaveManage from './pages/AppManagement/Leave/LeaveManage';
import FaqList from './pages/AppManagement/Faq/FaqList';
import WordOfTheDayList from './pages/AppManagement/WordOfTheDay/WordOfTheDayList';
import NewsLetterList from './pages/AppManagement/NewsLetter/NewsLetterList';
import NewsLetterManage from './pages/AppManagement/NewsLetter/NewsLetterManage';
import SyllabusList from './pages/AppManagement/Syllabus/SysllabusList';
import SyllabusManage from './pages/AppManagement/Syllabus/SyllabusManage';
import EventList from './pages/AppManagement/Event/EventList';
import TimeTableList from './pages/AppManagement/TimeTable/TimeTableList';
import TimeTableManage from './pages/AppManagement/TimeTable/TimeTableManage';
import CircularManage from './pages/AppManagement/Circular/CircularManage';
import CircularList from './pages/AppManagement/Circular/CircularList';
import NotificationList from './pages/AppManagement/Notification/NotificationList';
import NotificationManage from './pages/AppManagement/Notification/NotificationManage';
import AttendanceTeacherMap from './pages/AppManagement/AttendanceTeacherMap/AttendanceTeacherMap';
import UploadTimeTableManage from './pages/AppManagement/TimeTable/UploadTimeTable/UploadTimeTableManage';
import UploadTimeTableList from './pages/AppManagement/TimeTable/UploadTimeTable/UploadTimeTableList';
import AttendanceReport from './pages/AppManagement/Attendence/AttendanceReport';
import AttendanceDashboard from './pages/AppManagement/Dashboard/AttendanceDashboard';
import StudentActivationKey from './pages/AppManagement/ActivationKeyGenerate/ActivationKeyGenerate';
import ExamConfigList from './pages/ExamConfiguration/ExamConfigurationList';
import ExamConfigManage from './pages/ExamConfiguration/ExamConfigurationManage';
import ExamBulkCreate from './pages/Exam/ExamBulkCreate';
import StudentSubjectEnrollManage from './pages/StudentSubjectMapping/StudentSubjectEnroll/StudentSubjectEnrollManage';
import ExamConfigBulkCreate from './pages/Exam/ExamConfigBulkCreate';

const Routing = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />

      {/* Wrap all protected routes inside TheLayout */}
      <Route element={<ProtectedRoute component={TheLayout} />}>
        <Route path="/" element={<Dashboard />} />

        <Route path="/organization/list" element={<OrganizationList />} />
        <Route path="/organization/create" element={<OrganizationCreate />} />
        <Route path="/organization/update/:id" element={<OrganizationCreate />} />

        <Route path="/session/list" element={<SessionList />} />
        <Route path="/session/create" element={<SessionCreate />} />
        <Route path="/session/update/:id" element={<SessionCreate />} />

        <Route path="/program/list" element={<ProgramList />} />
        <Route path="/program/create" element={<ProgramCreate />} />
        <Route path="/program/update/:id" element={<ProgramCreate />} />

        <Route path="/subject/list" element={<SubjectList />} />
        <Route path="/subject/create" element={<SubjectCreate />} />
        <Route path="/subject/update/:id" element={<SubjectCreate />} />

        <Route path="/trait/list" element={<TraitList />} />
        <Route path="/trait/create" element={<TraitCreate />} />
        <Route path="/trait/update/:id" element={<TraitCreate />} />

        <Route path="/template/list" element={<TemplateList />} />
        <Route path="/template/create" element={<TemplateManage />} />
        <Route path="/template/update/:id" element={<TemplateManage />} />

        <Route path="/assessment/list" element={<AssessmentList />} />
        <Route path="/assessment/create" element={<AssessmentManage />} />
        <Route path="/assessment/update/:id" element={<AssessmentManage />} />

        <Route path="/student/subject/mapping/list" element={<StudentSubjectMappingList />} />
        <Route path="/student/subject/mapping/create" element={<StudentSubjectMappingManage />} />
        <Route path="/student/subject/mapping/update" element={<StudentSubjectMappingManage />} />

        <Route path="/student/subject/enroll" element={<StudentSubjectEnrollManage />} />

        <Route path="/score/schemes/list" element={<ScoreSchemesList />} />
        <Route path="/score/schemes/create" element={<ScoreSchemesCreate />} />
        <Route path="/score/schemes/update/:id" element={<ScoreSchemesCreate />} />

        <Route path="/evaluation/type/list" element={<EvaluationTypeList />} />
        <Route path="/evaluation/type/create" element={<EvaluationTypeCreate />} />
        <Route path="/evaluation/type/update/:id" element={<EvaluationTypeCreate />} />

        <Route path="/exam/config/list" element={<ExamConfigList />} />
        <Route path="/exam/config/create" element={<ExamConfigManage />} />
        <Route path="/exam/config/update/:id" element={<ExamConfigManage />} />
        <Route path="/exam/clone/config/create/:id" element={<ExamConfigManage />} />

        <Route path="/exam/list" element={<ExamList />} />
        <Route path="/exam/create" element={<ExamManage />} />
        <Route path="/exam/update/:id" element={<ExamManage />} />
        <Route path="/exam/clone/create/:id" element={<ExamManage />} />
        <Route path="/bulk/exam/create" element={<ExamBulkCreate />} />
        <Route path="/bulk/exam/config/create" element={<ExamConfigBulkCreate />} />

        <Route path="/assessment/marking/list" element={<AssessmentMarkingList />} />
        <Route path="/assessment/participants/list/:id" element={<AssessmentParticipantsMarking />} />
        <Route path="/assessment/marking/update/:id" element={<AssessmentParticipantsMarking />} />

        <Route path="/marking/approval/list" element={<MarkingApprovalList />} />
        <Route path="/marking/approval/create" element={<MarkingApprovalManage />} />
        <Route path="/marking/approval/update/:id" element={<MarkingApprovalManage />} />

        <Route path="/publish/result/list" element={<PublishResultList />} />
        <Route path="/publish/result/manage" element={<PublishResultManage />} />
        <Route path="/publish/result/update/:id" element={<PublishResultManage />} />

        <Route path="/student/list" element={<StudentList />} />
        <Route path="/student/create" element={<StudentManage />} />
        <Route path="/student/update/:id" element={<StudentManage />} />

        <Route path="/student/promote" element={<PromoteStudent />} />
        <Route path="/promoted/student/manage" element={<ManagePromotedStudents />} />

        <Route path="/student/sync" element={<SyncStudentList />} />

        <Route path="/teacher/list" element={<TeacherList />} />
        <Route path="/teacher/mapping/create/:id" element={<TeacherMappingManage />} />
        <Route path="/teacher/mapping/update/:id" element={<TeacherMappingManage />} />

        <Route path="/user/list" element={<UserList />} />
        <Route path="/user/create" element={<UserCreate />} />
        <Route path="/user/update/:id" element={<UserCreate />} />

        <Route path="/role/list" element={<RoleList />} />
        <Route path="/role/permission" element={<RolePermission />} />
        <Route path="/role/permission/update/:id" element={<RolePermission />} />

        <Route path="/report/card/manage" element={<ReportCardManage />} />
        <Route path="/password/update/:id" element={<UpdatePassword />} />

        {/* APP MANAGEMENT ROUTES */}
        <Route path="/attendance/list" element={<AttendanceList />} />
        <Route path="/attendance/create" element={<AttendanceManage />} />
        <Route path="/attendance/update" element={<AttendanceManage />} />
        <Route path="/attendance/report" element={<AttendanceReport />} />
        <Route path="/attendance/teacher/mapping" element={<AttendanceTeacherMap />} />
        <Route path="/attendance/dashboard" element={<AttendanceDashboard />} />
        <Route path="/generate/activation/key" element={<StudentActivationKey />} />

        <Route path="/leave/list" element={<LeaveList />} />
        <Route path="/leave/create" element={<LeaveManage />} />
        <Route path="/leave/update/:id" element={<LeaveManage />} />

        <Route path="/faq" element={<FaqList />} />
        <Route path="/word-of-the-day/" element={<WordOfTheDayList />} />

        <Route path="/newsletter/list" element={<NewsLetterList />} />
        <Route path="/newsletter/create" element={<NewsLetterManage />} />
        <Route path="/newsletter/update/:id" element={<NewsLetterManage />} />

        <Route path="/syllabus/list" element={<SyllabusList />} />
        <Route path="/syllabus/create" element={<SyllabusManage />} />
        <Route path="/syllabus/update/:id" element={<SyllabusManage />} />

        <Route path="/event/list" element={<EventList />} />
        <Route path="/timetable/list" element={<TimeTableList />} />
        <Route path="/timetable/create" element={<TimeTableManage />} />
        <Route path="/timetable/update/:id" element={<TimeTableManage />} />

        {/* UPLOAD TIMETABLE ROUTES */}
        <Route path="/upload/timetable/list" element={<UploadTimeTableList />} />
        <Route path="/upload/timetable/create" element={<UploadTimeTableManage />} />
        <Route path="/upload/timetable/update/:id" element={<UploadTimeTableManage />} />

        <Route path="/circular/list" element={<CircularList />} />
        <Route path="/circular/create" element={<CircularManage />} />
        <Route path="/circular/update/:id" element={<CircularManage />} />

        <Route path="/notification/list" element={<NotificationList />} />
        <Route path="/notification/create" element={<NotificationManage />} />
        <Route path="/notification/update/:id" element={<NotificationManage />} />
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default Routing;
