import React from 'react';
import './DataNotFound.css'; // Import the custom CSS for styling

const DataNotFound = () => {
  return (
    <div className="not-found-card-container card">
      <div className="not-found-card">
        <div className="card-body text-center">
         {/* <img
            src="https://via.placeholder.com/150" // Placeholder image, replace with actual image if needed
            alt="Not Found"
            className="not-found-image"
          />*/}
          <h3 className="card-title mt-3">No Data Available</h3>
          <p className="card-text">
            Sorry, we couldn’t find any data to display. Please try again later or check your filters.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DataNotFound;
