import React, { useState, useEffect } from "react";
import { fetchPrograms } from "../../redux/program/programActions";
import { FaGraduationCap } from "react-icons/fa";
import { StudentRow } from "./StudentRow";
import { PDFDocument } from 'pdf-lib';
import { termOptions } from "../../globalConstants";
import Loader from "../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { getClassWiseStudentList } from "../../services/StudentService";
import { downloadReportCard, downloadExcelReport } from "../../services/ReportCardService";
import Select from "../../components/Select/Select";
import Button from "../../components/Button/Button";

function ReportCardManage() {
  const [studentList, setStudentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState("");
  const [program_id, setProgramId] = useState("");
  const dispatch = useDispatch();
  const programData = useSelector((state) => state.program.programs.data);

  const getStudentList = async (program_id) => {
    let session_id = localStorage.getItem("session_id");
    setLoading(true);

    try {
      const response = await getClassWiseStudentList(program_id);
      if (response.status === 200) {
        const studentList = response.data.data;
        setStudentList(studentList);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(fetchPrograms());
  }, [fetchPrograms]);

  const changeHandler = async (e) => {
    const value = e.target.value;
    if (e.target.name === "program_id") {
      setProgramId(value);
      getStudentList(value);
    }
    if (e.target.name === "term") {
      setTerm(value);
    }
  };
  //...Other imports and component code ...

  const downloadAllResult = async (studentList, term) => {
    try {
      const cls = programData.find((program) => program.id == program_id).name;
      const mergedPdf = await PDFDocument.create();
      enqueueSnackbar('Please wait, it will take time to download the complete results', { variant: 'info' });

      for (const student of studentList) {
        const student_id = student.id;
        const name = student.first_name;
        const session_id = localStorage.getItem('session_id');

        const response = await downloadReportCard(student_id, session_id, term, { responseType: 'arraybuffer' });
        if (response.status === 200) {
          const pdfBytes = new Uint8Array(response.data);
          const pdfDoc = await PDFDocument.load(pdfBytes);

          const pages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
          pages.forEach((page) => mergedPdf.addPage(page));
          enqueueSnackbar('Result of ' + name + `and rollno. ${student.roll_no} downloaded successfully`, { variant: 'success' });
        }
      }
      const mergedPdfBytes = await mergedPdf.save();

      // Download the merged PDF
      const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute(
        'download',
        `Merged-ReportCards-Class-${cls}-Term-${term}.pdf`
      );
      document.body.appendChild(link);
      link.click();

    } catch (error) {
      enqueueSnackbar('Exam Marking Not Yet Completed!', { variant: 'error' });
      // console.error('Error merging PDFs:', error);
      return 1;
    }
  };

  const downloadExcelResult = async () => {
    try {
      const cls = programData.find((program) => program.id == program_id).name;
      const session_id = localStorage.getItem('session_id');
      setLoading(true);
      const response = await downloadExcelReport(program_id, session_id, term, { responseType: 'arraybuffer' });
      const arrayBuffer = response.data;
      // Create a blob from the array buffer
      const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      // Create a download link and trigger the download
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${cls}-term-${term}.xlsx`;
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      enqueueSnackbar('Internal Server Error!', { variant: 'error' });
      console.log('Error downloading Excel file:', error);
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <div className="program-list">
      <div className="animated">
        <div className="card">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div className="main-title-page page-title d-flex align-items-center">
                <div className="mx-2">
                  <FaGraduationCap />
                </div>
                <span>Report Card List</span>
              </div>
            </div>
          </div>
          <div className="row card-body">
            <div className="col-md-6">
              <Select
                label="Term"
                name="term"
                placeHolder={"Select Term"}
                value={term}
                onChangeHandler={changeHandler}
                options={termOptions}
              />
            </div>
            <div className="col-md-6">
              <Select
                label="Class"
                name="program_id"
                placeHolder={"Select Class"}
                onChangeHandler={changeHandler}
                options={programData}
              />
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="card-body evaluating-table">
              <table className="table table-hover table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Sr.NO</th>
                    <th>Roll NO</th>
                    <th>Admission NO</th>
                    <th>Student Name</th>
                    <th>Class</th>
                    <th>Preview Result</th>
                    <th>
                      Download Result <Button
                        className="dropdown-toggle ml-2"
                        size="sm"
                        onClick={() => downloadAllResult(studentList, term)}
                      >
                        All pdf
                      </Button>
                      <Button
                        className="dropdown-toggle mx-2"
                        size="sm"
                        onClick={() => downloadExcelResult(term)}
                      >
                        All Excel
                      </Button>
                    </th>
                  </tr>
                </thead>
                {studentList && studentList.length > 0 ? (
                  <tbody>
                    {studentList.map((student, index) => (
                      <StudentRow
                        key={index}
                        student={student}
                        term={term}
                        index={index}
                      />
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr align="center">
                      <td colSpan="6">No Record Found</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default ReportCardManage;
