import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getAssessmentParticipants = async (assessmentId) => {
  try {
    const response = await axios.get(`${ENDPOINTS.ASSESSMENT_MARKING.GET_ASSESSMENT_PARTICIPANTS}/${assessmentId}`,{
      headers: {
        'session-id': localStorage.getItem('session_id'),
      }
    });
    console.log(response ,"getAssessmentParticipants");
    return response;
  } catch (error) {
    throw error;
  }
}

export const createAssessmentMarking = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.ASSESSMENT_MARKING.CREATE, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateAssessmentMarking = async (data) => {
  try {
    const response = await axios.post(`${ENDPOINTS.ASSESSMENT_MARKING.UPDATE}`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
