import React, { useState, useEffect } from 'react';
import Card from '../../../components/Card/Card';
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import NotificationForm from './NotificationForm';
import { fetchPrograms } from '../../../redux/program/programActions';
import { useDispatch, useSelector } from 'react-redux';
import {getNotificationById, createNotification, updateNotification } from '../../../services/AppManagement/NotificationService';
import { getClassWiseStudentList } from '../../../services/StudentService';

const NotificationManage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.program);
  const programData = state.programs.data;
  const params = useParams();
  const [student_ids, setStudentIds] = useState([]);
  const [notice, setNotice] = useState('');
  const [studentList, setStudentList] = useState([]);
  const [student_loader, setStudentLoader] = useState(false);
  const [program_id, setProgramId] = useState('');
  const id = params.id;

  const getUniqueNotification = async () => {
    try {
      const response = await getNotificationById(id);
      if (response.status === 200) {
        const data = response.data.data;
        setProgramId(data.program_id);
        setNotice(data.message);
        setStudentIds([data.student_id.toString()]);
        let student_list = [data.student];
        setStudentList(student_list);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchStudentList = async (program_id) => {
    const session_id = localStorage.getItem('session_id');
    try {
      setStudentLoader(true);
      const response = await getClassWiseStudentList(program_id);
      if (response.status === 200) {
        setStudentList(response.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setStudentLoader(false);
    }
  };

  const changeHandler = (event) => {
    const { name, value } = event.target;
    if (name === 'program_id') {
      setProgramId(value);
      fetchStudentList(value);
    } else {
      setNotice(value);
    }
  };

  const handleInputChange = (event) => {
    const { value, checked } = event.target;
    const student_id = value;
    if (checked) {
      setStudentIds([...student_ids, student_id]);
    } else {
      const updatedStudentIds = student_ids.filter((id) => id !== student_id);
      setStudentIds(updatedStudentIds);
    }
  };

  const selectAllStudent = (event) => {
    const student_ids = [];
    if (event.target.checked) {
      studentList.forEach((student) => {
        const id = student.id.toString();
        student_ids.push(id);
      });
    }
    setStudentIds(student_ids);
  };

  useEffect(() => {
    dispatch(fetchPrograms());
    if (id) {
      getUniqueNotification();
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let data = {
      notice: notice,
      session_id: localStorage.getItem('session_id'),
    };
    if (id) {
      data.student_id = student_ids[0];
    }
    else {
      data.student_ids = student_ids;
    }

    try {
      const response = id
        ? await updateNotification(id, { data })
        : await createNotification({ data });
      if (response.status === 200) {
        let msg = response.data.message.success;
        enqueueSnackbar(msg, { variant: 'success' });
        navigate('/notification/list');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const back = () => {
    navigate('/notification/list');
  };

  return (
    <Card componentName={id ? 'Update Notifiaction' : 'Add Notifiaction'} back={back}>
      <NotificationForm
        submitHandler={handleSubmit}
        changeHandler={changeHandler}
        handleInputChange={handleInputChange}
        selectAllStudent={selectAllStudent}
        id={id}
        programData={programData}
        program_id={program_id}
        notice={notice}
        student_ids={student_ids}
        studentList={studentList}
        student_loader={student_loader}
      />
    </Card>
  );
};
export default NotificationManage;
