import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from '../../components/Card/Card';
import { Button } from "react-bootstrap";
import { useParams, useNavigate } from 'react-router-dom';
import '../Exam/Exam.css';
import Loader from '../../components/Loader/Loader';
import { enqueueSnackbar } from 'notistack';
import { getClassWiseSubjects } from '../../services/SubjectServices';
import { getProgramWiseExamList } from '../../services/ExamService';
import { getClassWiseStudentList } from '../../services/StudentService';
import { getStudentSubjectMappingList } from '../../services/StudentSubjectMappingService';
import { getScoreSchemeByEvaluationType } from '../../services/ScoreSchemeService';
import { getExamConfigById, createExamConfig, updateExamConfig } from '../../services/ExamConfigService';
import ExamConfigurationForm from './ExamConfigurationForm';

const ExamConfigManage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [studentSubjectWise, setSetStudentSubjectWise] = useState(false);
  const [program_id, setProgramId] = useState("");
  const [gradeSchemeID, setGradeSchemeID] = useState("");
  const [grade_scheme_type, setGradeSchemeType] = useState("");
  const [student_ids, setStudentIds] = useState([]);
  const [scoreSchemesData, setScoreSchemesData] = useState([]);
  const [state, setState] = useState({
    name: '', start: '', end: '', term: '', is_active: 1,
    description: '', student_loader: false,
    loader: false, error: [], grade_method: "", startDate: '',
    endDate: '', sessionData: [], programData: [], subjectData: [], selectedSubjects: [],
    examList: [], evaluationTypeData: [], scoreSchemesData: [], gradingMethodsData: [],
    is_traits: false, maxScore: "",
    traits: [{ trait_id: "", trait_grade_scheme_type: "" }],
    studentList: [], attempts: "NO", total_attempts: "",
    freezed: false,
  });
  const session_id = localStorage.getItem("session_id");

  const getSubject = async (programId) => {
    try {
      const res = await getClassWiseSubjects(programId);
      const subjectData = res.data.data;
      setState((prevState) => ({ ...prevState, subjectData }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchScoreSchemesByEvalation = async (id) => {
    try {
      const response = await getScoreSchemeByEvaluationType(id);
      if (response.status === 200) {
        const scoreSchemesData = response.data.data;
        setScoreSchemesData(scoreSchemesData);
      }
    }
    catch (error) {
      console.error(error);
    }
  };

  const getStudentList = async (program_id, subject_id) => {
    let session_id = localStorage.getItem("session_id");
    setState((prevState) => ({ ...prevState, student_loader: true, studentList: [] }));
    try {
      let response;
      if (studentSubjectWise) {
        response = await getStudentSubjectMappingList(session_id, program_id, subject_id);
      } else {
        response = await getClassWiseStudentList(program_id);
      }
      if (response.status === 200) {
        setState((prevState) => ({ ...prevState, student_loader: false }));
        const studentList = response.data.data;
        setState((prevState) => ({ ...prevState, studentList }));
      }
    } catch (error) {
      setState((prevState) => ({ ...prevState, loader: false, student_loader: false }));
      console.error(error);
    }
    finally {
      setState((prevState) => ({ ...prevState, student_loader: false }));
    };
  };

  const changeStudentSubjectWise = (event) => {
    setState((prevState) => ({ ...prevState, student_loader: true, studentList: [] }));
    if (event.target.checked) {
      setSetStudentSubjectWise(event.target.checked);
    }
    else {
      setSetStudentSubjectWise(event.target.checked);
    }
  }

  const getExamList = async (program_id) => {
    setState((prevState) => ({ ...prevState, student_loader: true }));
    try {
      const response = await getProgramWiseExamList(program_id);
      if (response.status === 200) {
        setState((prevState) => ({ ...prevState, student_loader: false }));
        const examList = response.data.data;
        setState((prevState) => ({ ...prevState, examList }));
      }
    } catch (error) {
      setState((prevState) => ({ ...prevState, loader: false, student_loader: false }));
      console.error(error);
    }
  };

  const getUniqueEvent = async () => {
    setState((prevState) => ({ ...prevState, loader: true }));
    try {
      const response = await getExamConfigById(params.id);
      // const response = await getExamById(params.id, session_id);
      if (response.status === 200) {
        const data = response.data.data;
        const {
          id, term, description, start,
          end, program, subject, evaluation_type,
          grade_scheme, attempts, total_attempts,
          is_traits, traits, max_score,
        } = data;
        let name = data.name.id;
        getExamList(program.id);
        getSubject(program.id);
        setProgramId(program.id);
        setGradeSchemeID(grade_scheme.id);
        setGradeSchemeType(grade_scheme.type);
        setState((prevState) => ({
          ...prevState, loader: false,
          id, name, term, description, start,
          end, subject_id: subject.id, evaluation_type_id: evaluation_type.id,
          attempts, total_attempts, is_traits,
          traits: traits.map((item) => ({
            trait_grade_scheme_type: item.grade_scheme.type,
            label: item.subject_trait.name,
            value: item.subject_trait.id,
            trait_id: item.subject_trait.id,
            grade_scheme_id: item.grade_scheme.id,
          })),
          maxScore: max_score,
        }));
        fetchScoreSchemesByEvalation(evaluation_type.id);
      }
    } catch (error) {
      console.error(error);
      setState((prevState) => ({ ...prevState, loader: false }));
    }
    finally {
      setState((prevState) => ({ ...prevState, loader: false }));
    }
  }

  useEffect(() => {
    if (params.id) {
      getUniqueEvent();
    }
    const fetchData = async () => {
      try {
        const org_id = localStorage.getItem("organization_id");
        if (!org_id) {
          enqueueSnackbar("Please select any organization", { variant: 'error' });
        }
        const gradingMethodsResponse = await axios.get(`/grade/methods`);
        const gradingMethodsData = gradingMethodsResponse.data.data;
        setState((prevState) => ({ ...prevState, gradingMethodsData, loading: false }));
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Request was cancelled');
        } else {
          console.error('Error:', error);
        }
      }
    };
    fetchData();
  }, []);

  const addNewRow = () => {
    setState((prevState) => ({
      ...prevState,
      traits: [...prevState.traits, {}],
    }));
  };

  const clickOnDelete = (e, record) => {
    e.preventDefault();
    e.stopPropagation();
    setState((prevState) => ({
      ...prevState,
      traits: prevState.traits.filter((r) => r !== record),
    }));
  };

  const handleChangeTraits = (event, index) => {
    const { name, value, label } = event.target;
    const updatedTraits = [...state.traits];
    updatedTraits[index][name] = value;
    updatedTraits[index]["label"] = label;
    updatedTraits[index]["grade_scheme_id"] = gradeSchemeID
    updatedTraits[index]["trait_grade_scheme_type"] = grade_scheme_type;
    setState({ ...state, traits: updatedTraits });
  };

  const handleChangeTraitsScheme = (event, index) => {
    const { name, value } = event.target;
    const updatedTraits = [...state.traits];
    if (name === "grade_scheme_id") {
      scoreSchemesData.forEach((item) => {
        if (item.id == value) {
          updatedTraits[index]["grade_scheme_id"] = item.id;
          updatedTraits[index]["trait_grade_scheme_type"] = item.type;
        }
      });
    } else {
      updatedTraits[index][name] = value;
    }
    setState({ ...state, traits: updatedTraits });
  };

  const handleInputChange = (event) => {
    const { value, checked } = event.target;
    const student_id = value;
    if (checked) {
      setStudentIds([...student_ids, student_id]);
    } else {
      const updatedStudentIds = student_ids.filter((id) => id !== student_id);
      setStudentIds(updatedStudentIds);
    }
  };

  const selectAllStudent = (event) => {
    const student_ids = [];
    if (event.target.checked) {
      state.studentList.forEach((student) => {
        const id = student.id.toString();
        document.getElementById(id).checked = true;
        student_ids.push(id);
      });
    } else {
      state.studentList.forEach((student) => {
        const id = student.id;
        document.getElementById(id).checked = false;
      });
    }
    setStudentIds(student_ids);
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === "is_traits") {
      setState({ ...state, is_traits: checked });
    } else if (name === "gradeSchemeID") {
      const selectedItem = scoreSchemesData && scoreSchemesData.find((item) => Number(item.id) === Number(value));
      setGradeSchemeID(value);
      if (selectedItem) {
        setGradeSchemeType(selectedItem.type);
      }
    }
    else if (name === "evaluation_type_id") {
      setState({ ...state, evaluation_type_id: value });
      fetchScoreSchemesByEvalation(value);
    }
    else if (name === "program_id") {
      setProgramId(value);
      getStudentList(value, 0);
      getExamList(value);
      getSubject(value);
      setStudentIds([]);
    }
    else if (name === "is_active") {
      if (checked)
        setState({ ...state, is_active: 1 });
      else
        setState({ ...state, is_active: 0 });
    }
    else {
      setState(() => ({ ...state, [name]: value }));
    }
  };

  const onSubjectSelect = (event) => {
    setState((prevState) => ({
      ...prevState,
      subject_ids: event,
      selectedSubjects: event,
    }));
    if (studentSubjectWise)
      getStudentList(program_id, event[0].id);
  };

  const onSubjectRemove = (event) => {
    setState((prevState) => ({
      ...prevState,
      subject_ids: event,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const pathname = window.location.pathname;
    const clone = pathname.includes('clone');
    setState((prevState) => ({
      ...prevState, loader: true,
      attempts: "NO", total_attempts: ""
    }));
    const session_id = localStorage.getItem("session_id");
    const grade_scheme_id = gradeSchemeID;
    const max_score = state.maxScore;
    let traits;

    if (!state.is_traits) {
      traits = [];
    } else {
      traits = state.traits.map((item) => {
        return {
          trait_id: item.trait_id,
          grade_scheme_id: item.grade_scheme_id,
        };
      });
    }

    const {
      name, term, start, end, evaluation_type_id,
      attempts, total_attempts, grade_method, description,
      is_traits, is_active,
    } = state;

    const data = {
      name, term, session_id, start, end,
      program_id, evaluation_type_id, grade_scheme_id,
      attempts, total_attempts, grade_method, description,
      max_score, student_ids, is_traits, traits, is_active,
    };

    try {
      if (params.id) {
        data.subject_id = state.subject_id;

        let response;
        if (clone) {
          delete data.id;
          data.subject_ids = [data.subject_id];
          response = await createExamConfig({ data });
        }
        else {
          response = await updateExamConfig(params.id, { data });
        }

        if (response.status === 200) {
          let msg = response.data.message.success;
          enqueueSnackbar(msg, { variant: 'success' });
          navigate("/exam/config/list");
        }
        else {
          enqueueSnackbar(response.data.message.error, { variant: 'error' });
        }
        setState((prevState) => ({ ...prevState, loader: false }));
      } else {
        data.subject_ids = state.subject_ids && state.subject_ids.map((item) => item.id);
        const response = await createExamConfig({ data });
        setState((prevState) => ({ ...prevState, loader: false }));
        if (response.status === 200) {
          enqueueSnackbar(response.data.message.success, { variant: 'success' });
          if (!state.freezed) {
            navigate("/exam/config/list");
          }
        }
      }
    } catch (error) {
      setState((prevState) => ({ ...prevState, loader: false }));
      console.error(error);
      enqueueSnackbar("Something went wrong!", { variant: 'error' });
    }
    finally {
      setState((prevState) => ({ ...prevState, loader: false }));
    }
  };

  const back = () => {
    navigate("/exam/config/list");
  };

  return (
    <Card
      componentName={state.id ? "Update ExamConfig" : "Add ExamConfig"}
      back={back}
      freezed={<Button variant={state.freezed ? "primary" : "secondary"}
        onClick={() => setState((prevState) =>
          ({ ...prevState, freezed: !prevState.freezed }))}>
        {!state.freezed ? "Freeze" : "Unfreeze"}
      </Button>}
    >
      {state.loader ?
        <Loader /> :
        <ExamConfigurationForm
          submitHandler={handleSubmit}
          changeHandler={handleChange}
          changeTraitHandler={handleChangeTraits}
          handleChangeTraitsScheme={handleChangeTraitsScheme}
          selectAllStudent={selectAllStudent}
          inputChangeHandler={handleInputChange}
          addRow={addNewRow}
          clickOnDelete={clickOnDelete}
          data={state}
          program_id={program_id}
          scoreSchemesData={scoreSchemesData}
          gradeSchemeID={gradeSchemeID}
          grade_scheme_type={grade_scheme_type}
          student_ids={student_ids}
          subjectData={state.subjectData}
          onSubjectSelect={onSubjectSelect}
          onSubjectRemove={onSubjectRemove}
          selectedSubjects={state.selectedSubjects}
          studentSubjectWise={studentSubjectWise}
          changeStudentSubjectWise={changeStudentSubjectWise}
        />}
    </Card>
  );
};
export default ExamConfigManage;
