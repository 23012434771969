import React from 'react';
import ExamConfigForm from '../Exam/ExamConfigForm';
import { connect } from 'react-redux';
import { fetchPrograms } from '../../redux/program/programActions';
import { fetchEvaluationTypes } from '../../redux/evaluationType/evaluationTypeActions';
import { fetchTraits } from '../../redux/trait/traitActions';
import { Form, Button } from 'react-bootstrap';

const ExamConfigurationForm = props => {
  return (
    <Form onSubmit={props.submitHandler} onChange={props.changeHandler}>
      <ExamConfigForm
        props={props}
      />
      <div className="col-md-12 d-flex justify-content-end">
        <Button variant="success" className='mt-2'
          type="submit">Submit</Button>
      </div>
    </Form>
  );
};
const mapdataToprops = data => {
  return {
    programData: data.program.programs.data,
    evaluationType: data.evaluationType.evaluationTypes.data,
    traitData: data.trait.traits.data,
  }
}
const mapDispatchToprops = dispatch => {
  return {
    fetchPrograms: () => dispatch(fetchPrograms()),
    fetchEvaluationTypes: () => dispatch(fetchEvaluationTypes()),
    fetchTraits: () => dispatch(fetchTraits()),
  }
}

export default connect(mapdataToprops, mapDispatchToprops)(ExamConfigurationForm);
