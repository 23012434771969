import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Switch from "../../../components/Switch/Switch";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const LeaveTable = ({ data, leaveIDs, permissions, isLoader, selectAllLeaveHandler,
  selectLeaveHandler, leaveApproverHandler, currentPage }) => {
  return (
    <div className='table-responsive' style={{ overflow: 'auto' }}>
      <table className="table table-bordered table-hover table-stripe" style={{ overflow: "scroll" }}>
        <thead>
          <tr>
            <th className="text-uppercase">SNo</th>
            <th className="text-uppercase">RollNo</th>
            <th className="text-uppercase">IDNumber</th>
            <th className="text-uppercase">Name</th>
            <th className="text-uppercase">Class</th>
            <th className="text-uppercase">From_Date</th>
            <th className="text-uppercase">To_Date</th>
            <th className="text-uppercase">Applied_Date</th>
            <th className="text-uppercase">Reason_for_Leave</th>
            <th className="text-uppercase">Response_Date</th>
            <th className="text-uppercase">Status</th>
            <th className="text-uppercase">Action</th>
            <th className="text-uppercase">
              <Switch
                // label={'Select All'}
                value={'1'}
                checked={leaveIDs.length === data.length}
                name='all'
                id='all'
                onChange={selectAllLeaveHandler}
              /></th>
          </tr>
        </thead>
        <tbody>
          {data.map((rowData, idx) => (
            <tr key={rowData.id}>
              <td>{(50*currentPage)+(idx + 1)}</td>
              <td>{rowData.student_program.roll_no}</td>
              <td>{rowData.student.id_number}</td>
              <td>{rowData.student.first_name + " " + rowData.student.last_name}</td>
              <td>{rowData.program.name}</td>
              <td>{rowData.from_date}</td>
              <td>{rowData.to_date}</td>
              <td>{rowData.created_at}</td>
              <td>{rowData.apply_reason}</td>
              <td>{rowData.updated_at}</td>
              <td>
                {(rowData.status === 'pending') ?
                  <span className="badge bg-warning">{rowData.status}</span> : null}
                {(rowData.status === 'approved') ?
                  <span className="badge bg-success">{rowData.status}</span> : null}
                {(rowData.status === 'rejected') ?
                  <span className="badge bg-danger">{rowData.status}</span> : null}
              </td>
              <td>
                {permissions.includes('leave.update') &&
                  <Link to={`/leave/update/${rowData.id}`}>
                    <FontAwesomeIcon
                      icon={faEdit}
                      color="#321fdb"
                      style={{
                        marginRight: "10px",
                        cursor: "pointer"
                      }}
                    />
                  </Link>
                }
              </td>
              <td>
                {permissions.includes('leave.approval') &&
                  <Switch
                    id={rowData.id}
                    name={`leaveIDs${rowData.id}`}
                    value={rowData.id}
                    checked={leaveIDs.includes(rowData.id.toString())}
                    onChange={selectLeaveHandler}
                  />
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="justify-content-end d-flex">
        <Button
          variant="success"
          disabled={isLoader}
          onClick={(e) => { leaveApproverHandler(e, "approved") }}
        >
          APPROVE
        </Button>
        <Button
          variant="danger"
          className="ml-1 mx-2"
          disabled={isLoader}
          onClick={(e) => { leaveApproverHandler(e, "rejected") }}
        >
          REJECT
        </Button>
      </div>
    </div>
  );
};

export default LeaveTable;
