import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { enqueueSnackbar } from 'notistack';
import StudentSubjectMappingForm from './StudentSubjectMappingForm';
import { Button } from 'react-bootstrap';
import { getClassWiseStudentList, getFilteredStudents } from '../../services/StudentService';
import { getClassWiseSubjects } from '../../services/SubjectServices';
import { createStudentSubjectMapping, getStudentSubjectMappingList, updateStudentSubjectMapping } from '../../services/StudentSubjectMappingService';

const StudentSubjectMappingManage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [program_id, setProgramId] = useState("");
  const [student_ids, setStudentIds] = useState([]);
  const [SubjectList, setSubjectList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [student_loader, setStudentLoader] = useState(false);
  const pathname = window.location.pathname;

  const getSubjectList = async (programId) => {
    try {
      const res = await getClassWiseSubjects(programId);
      const subjectData = res.data.data;
      setSubjectList(subjectData);
    } catch (error) {
      console.log(error);
    }
  };

  const getStudentList = async (program_id) => {
    let session_id = localStorage.getItem("session_id");
    try {
      setStudentList([]);
      setStudentLoader(true);
      const response = await getClassWiseStudentList(program_id);
      if (response.status === 200) {
        const studentList = response.data.data;
        setStudentList(studentList);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setStudentLoader(false);
    }
  };

  const fetchData = async (subject_id) => {
    let session_id = localStorage.getItem("session_id");
    try {
      setLoader(true);
      setStudentIds([]);
      let response = await getStudentSubjectMappingList(session_id, program_id, subject_id);
      if (response.status === 200) {
        let data = response.data.data;
        setStudentIds(data.map((student) => student.id.toString()));
      }
    }
    catch (error) {
      setStudentIds([]);
      console.log("Error", error);
    }
    finally {
      setLoader(false);
    }
  }

  const studentChangeHandler = (event) => {
    const { value, checked } = event.target;
    const student_id = value;
    if (checked) {
      setStudentIds([...student_ids, student_id]);
    } else {
      const updatedStudentIds = student_ids.filter((id) => id !== student_id);
      setStudentIds(updatedStudentIds);
    }
  };

  const selectAllStudent = (event) => {
    setStudentIds(event.target.checked ? studentList.map((student) => student.id.toString()) : []);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "program_id") {
      setProgramId(value);
      setStudentIds([]);
      getStudentList(value);
      getSubjectList(value);
    }
  };

  const onSubjectSelect = (event) => {
    setSelectedSubjects(event);
    if (pathname === "/student/subject/mapping/update") {
      let subject_id = event[0].id;
      fetchData(subject_id);
    }
  };

  const onSubjectRemove = (event) => {
    setSelectedSubjects(event);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const session_id = localStorage.getItem("session_id");
    const subject_ids = selectedSubjects.map((subject) => subject.id.toString());
    const data = { session_id, program_id, student_ids, subject_ids };
    try {
      let pathname = window.location.pathname;
      setLoader(true);
      if (pathname === "/student/subject/mapping/update") {
        const response = await updateStudentSubjectMapping(data);
        // await axios.patch(`/student-subject-mapping/update`, { data });
        setLoader(false);
        if (response.status === 200) {
          let msg = response.data.message.success;
          enqueueSnackbar(msg, { variant: 'success' });
          navigate("/student/subject/mapping/list");
        }
        else {
          enqueueSnackbar(response.data.message.error, { variant: 'error' });
        }
      }
      else {
        const response = await createStudentSubjectMapping(data);
        setLoader(false);
        if (response.status === 201) {
          enqueueSnackbar(response.data.message.success, { variant: 'success' });
          navigate("/student/subject/mapping/list");
        }
      }
    }
    catch (error) {
      console.error(error);
    }
  };

  const back = () => {
    navigate("/student/subject/mapping/list");
  };

  return (
    <div className='card'>
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <div className="main-title-page page-title d-flex align-items-center">
            <div className="mx-2">
            </div>
            <span>{pathname
              === "/student/subject/mapping/update" ?
              "Update Student Subject Mapping" : "Student Subject Mapping"
            }</span>
          </div>
          <Button variant="secondary" className='mx-2'
            onClick={back}>
            Back</Button>
        </div>
      </div>
      {loader ? <Loader /> : null}
      <div className="card-body">
        <StudentSubjectMappingForm
          student_loader={student_loader}
          program_id={program_id}
          student_ids={student_ids}
          studentList={studentList}
          subjectData={SubjectList}
          submitHandler={handleSubmit}
          changeHandler={handleChange}
          selectAllStudent={selectAllStudent}
          studentChangeHandler={studentChangeHandler}
          onSubjectSelect={onSubjectSelect}
          onSubjectRemove={onSubjectRemove}
          selectedSubjects={selectedSubjects}
        />
      </div>
    </div>
  );
};

export default StudentSubjectMappingManage;


