import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getExams = async (session_id, page) => {
  try {
    const response = await axios.get(ENDPOINTS.EXAM.GET, { params: { session_id, page } });
    return response; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
};

export const getFilteredExams = async (session_id, name, program_id, subject_id, evaluation_type_id, start, end, term, status, page) => {
  try {
    const response = await axios.get(`${ENDPOINTS.EXAM.GET_FILTERED}`, {
      params: {
        session_id: session_id,
        query: name,
        program_id: program_id,
        subject_id: subject_id,
        evaluation_type_id: evaluation_type_id,
        start: start,
        end: end,
        term: term,
        status: status,
        page: page
      }
    });
    return response; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
}

export const getExamById = async (id, session_id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.EXAM.GET_EXAM}/${id}`, {
      headers: {
        "session-id": session_id
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const createExam = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.EXAM.CREATE, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateExam = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.EXAM.UPDATE}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteExam = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.EXAM.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getProgramWiseSubjectList = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.EXAM.GET_PROGRAM_WISE_SUBJECTS}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getProgramWiseExamList = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.EXAM.GET_PROGRAM_WISE_EXAM}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const examActiveInActive = async (data) => {
  try {
    const response = await axios.post(`${ENDPOINTS.EXAM.STATUS_UPDATE}`, { data });
    return response;
  } catch (error) {
    throw error;
  }
}

export const bulkExamCreate = async (data) => {
  try {
    const response = await axios.post(`${ENDPOINTS.EXAM.BULK_CREATE}`, { data });
    return response;
  } catch (error) {
    throw error;
  }
}

export const bulkExamConfigCreate = async (data) => {
  try {
    const response = await axios.post(`${ENDPOINTS.EXAM.CONFIG_BULK_CREATE}`, { data });
    return response;
  } catch (error) {
    throw error;
  }
}
