import React from 'react';
import { Form } from "react-bootstrap";
import Loader from '../../../components/Loader/Loader';
import Switch from '../../../components/Switch/Switch';
import Button from '../../../components/Button/Button';
// import './AttendanceTeacherMapForm.css';

const AttendanceTeacherMapForm = ({ submitHandler, programData, program_ids,
  leave_approval_program_ids, onSelectAllApproverProgramHandler, onApproverProgramHandler, onSwitchChange, onInputChange, loader
}) => {
  return (
    <React.Fragment>
      {loader && <Loader />}
      <Form onSubmit={submitHandler}>
        <div className="row">
          <div className="col-md-12">
            <div className='bg-secondary m-2 p-2'>
              <label style={{ fontSize: '1.0rem', fontWeight: '700', color: "#000000" }}>Attendance Class</label>
            </div>
            <Switch
              label='Select All'
              value={'1'}
              checked={program_ids.length === programData.length}
              name='all'
              id='select-all'
              onChange={onSwitchChange}
            />
            <div className="row">
              <div className="col-md-12">
                {programData &&
                  programData.map((program, idx) => (
                    <div
                      className="form-check form-check-inline col-md-2"
                      key={idx}
                    >
                      <Switch
                        label={program.name}
                        id={program.id}
                        name="programIds"
                        value={program.id}
                        checked={program_ids.includes(program.id.toString())}
                        onChange={onInputChange}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-2">
            <Form.Label style={{ fontSize: '1.0rem', fontWeight: '700', color: "#000000" }}>Leave Approver Class</Form.Label>
            <Switch
              label='Select All'
              value={'1'}
              checked={leave_approval_program_ids.length === programData.length}
              name='leave_approval_program_ids-all'
              id='leave_approval_program_ids-all'
              onChange={onSelectAllApproverProgramHandler}
            />
            <div className="row">
              {programData &&
                programData.map((program, idx) => (
                  <div
                    className="form-check form-check-inline col-md-2"
                    key={idx + 1 * 10}>
                    <Switch
                      label={program.name}
                      id={`leave_approval_program_ids${program.id}`}
                      name="leave_approval_program_ids"
                      value={program.id}
                      checked={leave_approval_program_ids.includes(program.id.toString())}
                      onChange={onApproverProgramHandler}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-end mt-3">
          <div className="col-md-2 mx-3">
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default AttendanceTeacherMapForm;
