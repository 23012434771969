import React from 'react';
import { Form } from "react-bootstrap";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Switch from '../../../components/Switch/Switch';
import Loader from '../../../components/Loader/Loader';
import Select from '../../../components/Select/Select';
import Button from '../../../components/Button/Button';

const NotificationForm = ({ changeHandler, submitHandler, selectAllStudent, fetchStudentList, id,
  programData, notice, program_id, studentList, student_ids, handleInputChange, student_loader
}) => {

  return (
    <Form onSubmit={submitHandler}>
      <div className="row">
        <div className="col-md-12">
          <Form.Group>
            <Form.Label>Notice</Form.Label>
          </Form.Group>
          <CKEditor
            editor={ClassicEditor}
            data={notice}
            onChange={(event, editor) => {
              const data = editor.getData();
              changeHandler({ target: { name: 'notice', value: data } });
            }}
          />
        </div>
        {id ? "" : <div className='row'>
          <div className="col-md-12">
            <Select
              label="Class"
              name="program_id"
              value={program_id}
              onChangeHandler={changeHandler}
              options={programData}
              placeHolder="Select Class"
            />
          </div>
        </div>
        }
        <div className="row mt-3">
          <div className="col-md-12">
            {
              student_loader ?
                <Loader />
                :
                <Form.Group>
                  <table className="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <th>SrNo</th>
                        <th>Roll No</th>
                        <th>Admission No</th>
                        <th>Name</th>
                        <th>
                          <Switch
                            checked={(student_ids.length ===
                              (studentList ? studentList.length : null))
                              && studentList.length > 0 ? true : false}
                            name="all"
                            id="select-all"
                            value="1"
                            onChange={e => selectAllStudent(e)}
                            label={'Select All'}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody className="col-md-12">
                      {
                        studentList && studentList.map((student, index) =>
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{student.roll_no}</td>
                            <td>{student.id_number}</td>
                            <td>{student.first_name + " " + student.last_name}</td>
                            <td>
                              <div className="form-check form-check-inline">
                                {student_ids.length &&
                                  student_ids.indexOf(student.id.toString()) !== -1 ?
                                  <Switch
                                    name="student_id"
                                    id={student.id}
                                    checked={student_ids.indexOf(student.id.toString()) !== -1 ? true : false}
                                    value={student.id || ''}
                                    onChange={handleInputChange}
                                  /> :
                                  <Switch
                                    name="student_id"
                                    id={student.id}
                                    value={student.id || ''}
                                    onChange={handleInputChange}
                                  />
                                }
                              </div>
                            </td>
                          </tr>)}
                      {studentList.length === 0 ?
                        <tr align="center"><th colSpan="3">No Record Found</th></tr> : null}
                    </tbody>
                  </table>
                </Form.Group>}
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <div className="">
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Form>);
};
export default NotificationForm;
