import React from 'react';
import Select from '../../../components/Select/Select';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';

const LeaveFilter = ({ changeHandler, fetchLeave,
  programData, program_id, leave_date, status }) => {
  return (
    <div className="row">
      <div className="col-md-3 pb-mb-2">
        <Select
          name="program_id"
          value={program_id}
          placeHolder="Select Class"
          options={programData}
          onChangeHandler={(e) => changeHandler(e)}
        />
      </div>
      <div className="col-md-3 pb-mb-2">
        <Input
          name="leave_date"
          value={leave_date}
          type="date"
          onChangeHandler={changeHandler}
          placeholder="Leave Date"
          required={true}
        />
      </div>
      <div className="col-md-3 pb-mb-2">
        <Select
          name="status"
          value={status}
          placeHolder="Select Status"
          options={[
            { id: 'pending', name: 'Pending' },
            { id: 'approved', name: 'Approved' },
            { id: 'rejected', name: 'Rejected' }
          ]}
          onChangeHandler={(e) => changeHandler(e)}
        />
      </div>
      <div className="col-md-1">
        <Button className='view_btn'
          variant="primary"
          type="button"
          onClick={fetchLeave}>
          View
        </Button>
      </div>
    </div>
  );
};

export default LeaveFilter;
