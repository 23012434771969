import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const AttendanceChart = ({ data }) => {
  const chartRef = useRef();

  useEffect(() => {
    const margin = { top: 20, right: 30, bottom: 80, left: 60 };
    const width = 1600 - margin.left - margin.right;
    const height = 500 - margin.top - margin.bottom;

    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const x = d3
      .scaleBand()
      .domain(data.map(d => d.program.name))
      .range([0, width])
      .padding(0.2);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, d => Math.max(d.present, d.absent, d.leave))])
      .nice()
      .range([height, 0]);

    const subBandWidth = x.bandwidth() / 3; // Divide the bandwidth for three bars

    // Tooltip setup
    const tooltip = d3
      .select(chartRef.current)
      .append("div")
      .style("position", "absolute")
      .style("background", "#f4f4f4")
      .style("padding", "5px")
      .style("border", "1px solid #ccc")
      .style("border-radius", "5px")
      .style("visibility", "hidden")
      .style("pointer-events", "none");

    const showTooltip = (event, d) => {
      tooltip
        .html(`Program: ${d.program.name}<br>Present: ${d.present}<br>Absent: ${d.absent}<br>Leave: ${d.leave}`)
        .style("visibility", "visible");

      const tooltipWidth = tooltip.node().offsetWidth;
      const pageWidth = window.innerWidth;
      const mouseX = event.clientX;
      const mouseY = event.clientY;

      const tooltipLeft = mouseX + tooltipWidth + 10 > pageWidth
        ? mouseX - tooltipWidth - 900
        : mouseX - 300;

      tooltip
        .style("top", `${mouseY + 10}px`)
        .style("left", `${tooltipLeft}px`);
    };

    const hideTooltip = () => {
      tooltip.style("visibility", "hidden");
    };

    // Draw "present" bars
    svg.append("g")
      .selectAll(".bar-present")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar-present")
      .attr("x", d => x(d.program.name))
      .attr("y", d => y(d.present))
      .attr("width", subBandWidth)
      .attr("height", d => height - y(d.present))
      .attr("fill", "#82ca9d")
      .on("mouseover", showTooltip)
      .on("mousemove", showTooltip)
      .on("mouseout", hideTooltip);

    // Draw "absent" bars
    svg.append("g")
      .selectAll(".bar-absent")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar-absent")
      .attr("x", d => x(d.program.name) + subBandWidth)
      .attr("y", d => y(d.absent))
      .attr("width", subBandWidth)
      .attr("height", d => height - y(d.absent))
      .attr("fill", "#8884d8")
      .on("mouseover", showTooltip)
      .on("mousemove", showTooltip)
      .on("mouseout", hideTooltip);

    // Draw "leave" bars
    svg.append("g")
      .selectAll(".bar-leave")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar-leave")
      .attr("x", d => x(d.program.name) + subBandWidth * 2)
      .attr("y", d => y(d.leave))
      .attr("width", subBandWidth)
      .attr("height", d => height - y(d.leave))
      .attr("fill", "#d88484")
      .on("mouseover", showTooltip)
      .on("mousemove", showTooltip)
      .on("mouseout", hideTooltip);

    svg.append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end");

    svg.append("g").call(d3.axisLeft(y));

    svg.append("text")
      .attr("x", width / 2)
      .attr("y", height + margin.bottom - 10)
      .attr("text-anchor", "middle")
      .text("Program");

    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -height / 2)
      .attr("y", -margin.left + 10)
      .attr("text-anchor", "middle")
      .text("Count");

    return () => {
      d3.select(chartRef.current).select("svg").remove();
      d3.select(chartRef.current).select("div").remove();
    };
  }, [data]);

  return (
    <div style={{ overflowX: "auto" }}>
      <div ref={chartRef}></div>
    </div>
  );
};

export default AttendanceChart;
