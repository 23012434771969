import React, { useState, useEffect } from 'react';
import { Button, Form } from "react-bootstrap";
import { fetchPrograms } from '../../../redux/program/programActions';
import { fetchSessions } from "../../../redux/session/sessionActions";
import { FaArrowDown, FaUserAlt } from 'react-icons/fa';
import Loader from '../../../components/Loader/Loader';
import { enqueueSnackbar } from 'notistack';
import * as XLSX from 'xlsx';
import ExcelBulkUpload from '../../ExcelBulkUpload/ExcelBulkUpload';
import StudentPromoteSample from './StudentPromoteSample.json';
import { Link } from 'react-router-dom';
import CardHeader from '../../../components/Card/CardHeader';
import PromoteFilter from './PromoteFilter';
import StudentListTable from './StudentListTable';
import { getFilteredStudents, promoteStudents, bulkPromoteStudents, getClassWiseStudentList } from '../../../services/StudentService';
import { useDispatch, useSelector } from 'react-redux';

function PromoteStudent() {
  const [program_id, setProgramId] = useState('');
  const [session_id, setSessionId] = useState('');
  const [promote_program_id, setPromoteProgramId] = useState('');
  const [promote_session_id, setPromoteSessionId] = useState('');
  const [loader, setLoader] = useState(false);
  const [student_ids, setStudentIds] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [isBulk, setIsBulk] = useState(false);
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const organization_id = localStorage.getItem('organization_id');
  const [studentBulk, setStudentBulk] = useState([]);
  const dispatch = useDispatch();
  const programList = useSelector((state) => state.program.programs.data);
  const sessionData = useSelector((state) => state.session.sessions.data);

  useEffect(() => {
    dispatch(fetchPrograms({ selected: 0 }));
    dispatch(fetchSessions());
  }, [])

  const getStudentList = async (e) => {
    setStudentList([]);
    if (program_id === '' || session_id === '') {
      enqueueSnackbar("Please select class and session", { variant: "error" });
      return;
    }
    try {
      setLoader(true);
      const response = await getClassWiseStudentList(program_id);
      if (response.status === 200) {
        const data = response.data;
        setStudentList(data.data);
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoader(false);
    }

  }

  const handleInputChange = (event) => {
    const student_id = event.target.value;
    const value = event.target.value;
    let studentIds;

    if (event.target.checked) {
      studentIds = [...student_ids, student_id];
    } else {
      studentIds = student_ids.filter((id) => id !== value);
    }
    setStudentIds(studentIds);
  };

  const handleSwitchChange = (event) => {
    let student_ids;
    if (event.target.checked) {
      student_ids = studentList.map((student) => student.id.toString());
    } else {
      student_ids = [];
    }
    setStudentIds(student_ids);
  };

  const transferHandler = async () => {
    if (student_ids.length === 0) {
      enqueueSnackbar("Please select students", { variant: "error" });
      return;
    }
    if (promote_program_id === '' || promote_session_id === '') {
      enqueueSnackbar("Please select class and session", { variant: "error" });
      return;
    }
    try {
      setLoader(true);
      const roll_nos = studentList.filter((student) => student_ids.includes(student.id.toString())).map((student) => student.roll_no);
      const data = {
        student_ids: student_ids,
        program_id: promote_program_id,
        session_id: promote_session_id,
        roll_nos: roll_nos,
        organization_id: organization_id
      }
      const response = await promoteStudents(data);
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: "success" });
      }
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setLoader(false);
    }
  }

  const downloadExcel = (e) => {
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(StudentPromoteSample);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `student-promote-sample.xlsx`);
  }

  const excelFileReader = async (e) => {
    let sessionMap = {};
    sessionData.forEach((session) => {
      let name = session.name.toLowerCase().replace(/\s/g, '');
      sessionMap[name] = session.id;
    });

    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      let final_data = data.map((item) => {
        let session = item.session && item.session.toLowerCase().replace(/\s/g, '');
        let class_name = item.class.toLowerCase().replace(/\s/g, '');
        return {
          id_number: item.id_number,
          session_id: sessionMap[session],
          class: item.class,
          roll_no: item.roll_no,
        }
      })
      setStudentBulk(final_data);
    }
  }

  const bulkStudentPromote = async (e) => {
    if (studentBulk.length === 0) {
      enqueueSnackbar("Please select file", { variant: "error" });
      return;
    }

    try {
      setLoader(true);
      const data = {
        studentBulk: studentBulk,
        organization_id: organization_id
      }
      const response = await bulkPromoteStudents(data);
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: "success" });
      }
      else {
        if (response.data && response.data.message && response.data.message.error)
          enqueueSnackbar(response.data.message.error, { variant: "error" });
      }
      setLoader(false);
    }
    catch (error) {
      setLoader(false);
      console.log(error);
    }
  }



  return (
    <React.Fragment>
      <div className="card">
        <CardHeader
          title="Promote Students"
          icon={<FaUserAlt size={20} />}
          button={<div>
            {permissions.includes('student.promote') ?
              <Button variant="primary" className="mx-1" onClick={() => setIsBulk(!isBulk)}>
                {isBulk ? "Manual Create" : "Bulk Upload"}
              </Button>
              : ""
            }
            <Link to="/promoted/student/manage"
              className="btn btn-primary">View Promoted Students</Link>
          </div>}
        />
        {isBulk ?
          <ExcelBulkUpload
            excelFileReader={excelFileReader}
            downloadExcel={downloadExcel}
            bulkUploadFile={bulkStudentPromote}
          />
          :
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-center align-item-center">
                  <Form.Label className='fw-bold'>From</Form.Label>
                </div>
              </div>
              <PromoteFilter
                setSessionId={setSessionId}
                setProgramId={setProgramId}
                sessionData={sessionData}
                programList={programList}
              />
              <div className="col-md-2 mt-4">
                <Button variant="secondary" onClick={getStudentList}>Get Students</Button>
              </div>
            </div>
          </div>
        }
      </div>
      {!isBulk ? (<React.Fragment>
        <div className="col-md-12">
          <div className="d-flex justify-content-center align-item-center mt-2">
            <FaArrowDown size={20} />
          </div>
        </div>
        <div className="card mt-2">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-center align-item-center">
                  <Form.Label className='fw-bold'>To</Form.Label>
                </div>
              </div>
              <PromoteFilter
                setSessionId={setPromoteSessionId}
                setProgramId={setPromoteProgramId}
                sessionData={sessionData}
                programList={programList}
              />
              <div className="col-md-2 mt-4">
                <Button variant="success" onClick={transferHandler}>Transfer Students</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-3">
          <div className="card-body">
            {loader ? (<Loader />) :
              <StudentListTable
                handleSwitchChange={handleSwitchChange}
                handleInputChange={handleInputChange}
                studentList={studentList}
                student_ids={student_ids}
              />
            }
          </div>
        </div>
      </React.Fragment>) : null}
    </React.Fragment>
  );
}
export default PromoteStudent;
