import { ENDPOINTS } from "../endpoints";
import axios from "axios";

export const getWordOfTheDayById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.WORD_OF_THE_DAY.GET}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getWordOfTheDays = async (page) => {
  const session_id = localStorage.getItem("session_id");
  try {
    const response = await axios.get(ENDPOINTS.WORD_OF_THE_DAY.GET, {
      params: {
        session_id: session_id,
        page: page
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const createWordOfTheDay = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.WORD_OF_THE_DAY.CREATE, { data });
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateWordOfTheDay = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.WORD_OF_THE_DAY.UPDATE}/${id}`, { data });
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteWordOfTheDay = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.WORD_OF_THE_DAY.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const bulkUploadWordOfTheDay = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.WORD_OF_THE_DAY.BULK_UPLOAD, { data: data });
    return response;
  } catch (error) {
    throw error;
  }
}
