import React from 'react';
import { termOptions } from '../../globalConstants';
import Select from '../../components/Select/Select';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

const ExamFilter = (props) => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-4">
          <Select
            label={"Class"}
            name={"program_id"}
            value={props.program_id}
            placeHolder={"Select Class"}
            onChangeHandler={props.onChangeHandler}
            options={props.programData}
          />
        </div>
        <div className="col-md-4">
        <Select
            label={"Subject"}
            name={"subject_id"}
            value={props.subject_id}
            placeHolder={"Select Subject"}
            onChangeHandler={props.onChangeHandler}
            options={props.subjectData}
          />
        </div>
        <div className="col-md-4">
          <Select
            label="Exam Hearders"
            name="name"
            placeHolder={"Select Exam Headers"}
            value={props.name}
            onChangeHandler={props.onChangeHandler}
            options={props.examList}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
        <Select
            label="Evaluation"
            name="evaluation_type_id"
            placeHolder={"Select Evaluation Type"}
            value={props.evaluation_type_id}
            onChangeHandler={props.onChangeHandler}
            options={props.evaluationType}
          />
        </div>
        <div className="col-md-4">
          <Input
            label="From"
            type="date"
            name="start"
            value={props.start}
            onChangeHandler={props.onChangeHandler}
          />
        </div>
        <div className="col-md-4">
          <Input
            label="To"
            type="date"
            name="end"
            value={props.end}
            onChangeHandler={props.onChangeHandler}
          />
        </div>
        <div className="col-md-4">
          <Select
            label="Status"
            name="status"
            placeHolder={"Select Status"}
            value={props.status}
            onChangeHandler={props.onChangeHandler}
            options={props.statusData}
          />
        </div>
        <div className="col-md-4">
          <Select
            label="Term"
            name="term"
            placeHolder={"Select Term"}
            value={props.term}
            onChangeHandler={props.onChangeHandler}
            options={termOptions}
          />
        </div>
        <div className="col text-right pt-3 mt-3">
          <Button
            variant="primary"
            type="submit"
            className="mx-2"
            onClick={() => props.handleSearch({ selected: 0 })}
          >
            Search
          </Button>

          <Button
            variant="secondary"
            onClick={(event) => props.handleReset(event)}
          > Reset
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExamFilter;
